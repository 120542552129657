$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	countUpInit();
	packeryInit();
	audioInit();
	podcastInit();
	loadMoreItem();
	accordionCollapse();

	activeToScrollLeft();
	about4Tabslet();

	if ($('#site-sticky-nav').length) {
		var elm = document.querySelector('#site-sticky-nav');
		var ms = new MenuSpy(elm, {
			activeClass: 'active',
			threshold: $(header).outerHeight(),
		});
	}
});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();


// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		let mainMenu = $("header .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: "header .navbar-nav",
			desktopMethod: "appendTo",
			breakpoint: 1199.98,
		});
	},
	toggleMenuMobile: () => {
		$(buttonMenu).on('click', function () {
			$(this).toggleClass('active');
			mobileWrap.slideDown().toggleClass('active');
			$('.backdrop-mobile').fadeToggle();
		});

		$(mobileWrap).find('.close-mobile').on('click', function () {
			$(buttonMenu).removeClass('active');
			mobileWrap.fadeOut().removeClass('active');
			$('.backdrop-mobile').fadeOut();
		});

		$(document).on('click', function (e) {
			if (!$(e.target).closest(mobileWrap).length && !$(e.target).closest(buttonMenu).length) {
				$(buttonMenu).removeClass('active');
				mobileWrap.fadeOut().removeClass('active');
				$('.backdrop-mobile').fadeOut();
			}
		});
		$('.main-menu .menu-item-has-children > .sub-menu').each(function () {
			var toggleSubmenu = $('<span class="toggle-submenu"></span>');
			$(this).before(toggleSubmenu);
		});
		$('.main-menu .menu-item-has-children > .mega-menu').each(function () {
			var toggleMegaMenu = $('<span class="toggle-mega"></span>');
			$(this).before(toggleMegaMenu);
		});
		$('.main-menu .menu-item-has-children > .mega-wrap').each(function () {
			var toggleMegaWrap = $('<span class="toggle-wrap"></span>');
			$(this).before(toggleMegaWrap);
		});
		$('.main-menu .toggle-submenu, .main-menu .toggle-mega, .main-menu .toggle-wrap').on('click', function () {
			if (widthWindow < 1200) {
				$(this).toggleClass('active');
				$(this).next().slideToggle();
			}
		});
	},
	toggleSearch: () => {
		buttonSearch.on('click', function () {
			searchWrap.fadeToggle();
			searchWrap.find('input').trigger('focus');
			$('.backdrop-search').fadeToggle('fast');
		});

		$(document).on('click', function (e) {
			if (!$(e.target).closest(searchWrap).length && !$(e.target).closest(buttonSearch).length) {
				searchWrap.fadeOut('fast')
				$('.backdrop-search').fadeOut('fast');
			}
		});
	},
	init: () => {
		APP.backToTop();
		APP.mapping();
		APP.toggleMenuMobile();
		APP.toggleSearch();
	}
}

var defaultSettingSwiper = {
	preventInteractionOnTransition: true,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
}

const homeBannerSwiper = new Swiper(".banner-slider .swiper", {
	loop: true,
	autoplay: {
		delay: 4000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	effect: "fade",
	speed: 1000,
	...defaultSettingSwiper,
	navigation: {
		nextEl: ".banner-slider .button-next",
		prevEl: ".banner-slider .button-prev",
	},
	pagination: {
		el: ".banner-slider .swiper-pagination",
		clickable: true,
	},
});

$('.one-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: false,
		},
	};
	var centeredSlidesDefault = {};
	var effectDefault = {};
	var verticalDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	if ($(this).hasClass('effect-fade')) {
		effectDefault = {
			effect: 'fade',
		};
	}
	if ($(this).hasClass('is-vertical')) {
		verticalDefault = {
			direction: 'vertical',
		};
	}
	$(this).find('.swiper').addClass(`swiper-one-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-one-${index}`);
	$(this).find('.button-prev').addClass(`prev-one-${index}`);
	$(this).find('.button-next').addClass(`next-one-${index}`);
	const oneSlider = new Swiper(`.swiper-one-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		...centeredSlidesDefault,
		// ...effectDefault,
		...verticalDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		slideToClickedSlide: true,
		// loopAdditionalSlides: 1,
		navigation: {
			prevEl: `.one-slider .prev-one-${index}`,
			nextEl: `.one-slider .next-one-${index}`,
		},
		pagination: {
			el: `.one-slider .pagination-one-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			1200: {
				spaceBetween: 32,
			},
			1400: {
				spaceBetween: 40,
			},
		},
	});
});

const about1Slider = new Swiper(`.about-1-slider .swiper`, {
	...defaultSettingSwiper,
	speed: 1,
	spaceBetween: 16,
	slidesPerView: 1,
	effect: 'fade',
	navigation: {
		prevEl: `.about-1-slider .button-prev`,
		nextEl: `.about-1-slider .button-next`,
	},
	pagination: {
		el: `.about-1-slider .swiper-pagination`,
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		1200: {
			spaceBetween: 32,
		},
		1400: {
			spaceBetween: 40,
		},
	},
});
$('.about-1-circle-item').on('mouseenter', function () {
	const dataSwiperActive = $(this).data('circle-swiper');
	about1Slider.slideTo(dataSwiperActive);
}).on('mouseleave', function () {
	about1Slider.slideTo(0);
});
if (widthWindow < 1200 && $('.about-1-circle-item').length) {
	$('.about-1-circle-item').on('click', function () {
		$(this).addClass('active').siblings().removeClass('active');
		const dataSwiperActive = $(this).data('circle-swiper');
		about1Slider.slideTo(dataSwiperActive);
	})
	$(document).on('click', function (e) {
		if (!$(e.target).closest('.about-1-circle-item').length) {
			$('.about-1-circle-item').removeClass('active');
			about1Slider.slideTo(0);
		}
	});
}

const about4Slider = new Swiper(`.about-4-slider .swiper`, {
	...defaultSettingSwiper,
	speed: 1000,
	spaceBetween: 16,
	slidesPerView: 1,
	navigation: {
		prevEl: `.about-4-slider .button-prev`,
		nextEl: `.about-4-slider .button-next`,
	},
	breakpoints: {
		1200: {
			spaceBetween: 32,
		},
		1400: {
			spaceBetween: 40,
		},
	},
	on: {
		slideChangeTransitionEnd: function () {
			$('.about-4-section .site-links li').removeClass('active');
			$('.about-4-section .site-links li').eq(this.activeIndex).addClass('active');
		}
	}
});
$('.about-4-section .site-links li').on('click', function () {
	var dataSwiperActive = $(this).data('swiper-active');
	$(this).addClass('active').siblings().removeClass('active');
	about4Slider.slideTo(dataSwiperActive);
});

$('.two-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-two-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-two-${index}`);
	$(this).find('.button-prev').addClass(`prev-two-${index}`);
	$(this).find('.button-next').addClass(`next-two-${index}`);
	const twoSlider = new Swiper(`.swiper-two-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.two-slider .prev-two-${index}`,
			nextEl: `.two-slider .next-two-${index}`,
		},
		pagination: {
			el: `.two-slider .pagination-two-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
		},
	});
});

$('.three-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-three-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-three-${index}`);
	$(this).find('.button-prev').addClass(`prev-three-${index}`);
	$(this).find('.button-next').addClass(`next-three-${index}`);
	const threeSlider = new Swiper(`.swiper-three-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.three-slider .prev-three-${index}`,
			nextEl: `.three-slider .next-three-${index}`,
		},
		pagination: {
			el: `.three-slider .pagination-three-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
		},
	});
});

$('.four-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-four-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-four-${index}`);
	$(this).find('.swiper-scrollbar').addClass(`scrollbar-four-${index}`);
	$(this).find('.button-prev').addClass(`prev-four-${index}`);
	$(this).find('.button-next').addClass(`next-four-${index}`);
	const fourSlider = new Swiper(`.swiper-four-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1.25,
		navigation: {
			prevEl: `.four-slider .prev-four-${index}`,
			nextEl: `.four-slider .next-four-${index}`,
		},
		pagination: {
			el: `.four-slider .pagination-four-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		scrollbar: {
			el: `.four-slider .scrollbar-four-${index}`,
			hide: false,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 4,
				spaceBetween: 40,
			},
		},
	});
});

$('.five-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-five-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-five-${index}`);
	$(this).find('.button-prev').addClass(`prev-five-${index}`);
	$(this).find('.button-next').addClass(`next-five-${index}`);
	const fiveSlider = new Swiper(`.swiper-five-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.five-slider .prev-five-${index}`,
			nextEl: `.five-slider .next-five-${index}`,
		},
		pagination: {
			el: `.five-slider .pagination-five-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 5,
				spaceBetween: 40,
			},
		},
	});
});

$('.six-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	$(this).find('.swiper').addClass(`swiper-six-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-six-${index}`);
	$(this).find('.button-prev').addClass(`prev-six-${index}`);
	$(this).find('.button-next').addClass(`next-six-${index}`);
	const sixSlider = new Swiper(`.swiper-six-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 2,
		navigation: {
			prevEl: `.six-slider .prev-six-${index}`,
			nextEl: `.six-slider .next-six-${index}`,
		},
		pagination: {
			el: `.six-slider .pagination-six-${index}`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			576: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 4,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 5,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 6,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 6,
				spaceBetween: 40,
			},
		},
	});
});

$('.auto-slider').each(function (index) {
	var loopDefault = {
		loop: true,
	};
	var autoplayDefault = {
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	};
	var centeredSlidesDefault = {};
	var freeModeDefault = {}
	if ($(this).hasClass('no-loop')) {
		loopDefault = {
			loop: false,
		};
	}
	if ($(this).hasClass('no-autoplay')) {
		autoplayDefault = {
			autoplay: false,
		};
	}
	if ($(this).hasClass('one-autoplay')) {
		autoplayDefault = {
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	if ($(this).hasClass('centered-slides')) {
		centeredSlidesDefault = {
			centeredSlides: true,
			centeredSlidesBounds: true,
		};
	}
	if ($(this).hasClass('is-free-mode')) {
		freeModeDefault = {
			freeMode: true
		}
	}
	$(this).find('.swiper').addClass(`swiper-auto-${index}`);
	$(this).find('.swiper-pagination').addClass(`pagination-auto-${index}`);
	$(this).find('.button-prev').addClass(`prev-auto-${index}`);
	$(this).find('.button-next').addClass(`next-auto-${index}`);
	const autoSlider = new Swiper(`.swiper-auto-${index}`, {
		...defaultSettingSwiper,
		...loopDefault,
		...autoplayDefault,
		...freeModeDefault,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 'auto',
		navigation: {
			prevEl: `.auto-slider .prev-auto-${index}`,
			nextEl: `.auto-slider .next-auto-${index}`,
		},
		pagination: {
			el: `.auto-slider .pagination-auto-${index}`,
			clickable: true,
		},
		breakpoints: {
			1200: {
				spaceBetween: 32,
			},
			1400: {
				spaceBetween: 40,
			},
		},
	});
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		enableAutoReload: true,
	});
	// data-background-image="image.png"
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function countUpInit() {
	$('.section-count-up').each(function (indexSection) {
		$('.count-up').each(function (index) {
			$(this).attr('id', `countUp-${indexSection}-${index}`)
			const endVal = $(this).data('count');
			const decimal = $(this).data('decimal');
			const options = {
				separator: '.',
				decimal: decimal ? decimal : ',',
				decimalPlaces: decimal ? 1 : 0,
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: true,
				useEasing: true,
				useGrouping: true,
			};

			const countUp = new CountUp(`countUp-${indexSection}-${index}`, endVal, options);

			// if (!countUp.error) {
			// 	countUp.start();
			// } else {
			// 	console.error(countUp.error);
			// }
			var waypoint = new Waypoint({
				element: document.getElementsByClassName('section-count-up')[indexSection],
				handler: function (direction) {

					// if (direction == "up") {
					// 	countUp.reset();
					// } else {
					countUp.start();
					// }

				},
				offset: '50%'
			});

			// countUp.handleScroll();
		});
	});
}

function accordionCollapse() {
	$('.accordion-item .accordion-head').on('click', function () {
		$(this)
			.parent()
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.next().slideToggle()
			.parent()
			.siblings().find('.accordion-content').slideUp()
	});
}

function activeToScrollLeft() {
	$('.site-nav-list li.active, .site-links li.active, .site-menu-mobile li.current-menu-item').each(function (index) {
		const $this = $(this);
		const $siteNavList = $this.parent();

		if ($this.length > 0) {
			$siteNavList.scrollLeft($this.offset().left - $siteNavList.offset().left);
		}
	});
}

function about4SliderInit() {
	const about4Slider = new Swiper(`.about-4-section .swiper`, {
		...defaultSettingSwiper,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.about-4-section .button-prev`,
			nextEl: `.about-4-section .button-next`,
		},
		pagination: {
			el: `.about-4-section .swiper-pagination`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1200: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
		},
	});
}

function about4Tabslet() {
	$('[data-toggle="about-4-tabslet"]').tabslet({
		animation: true
	});
	$('[data-toggle="about-4-tabslet"]').on("_after", function () {
		about4SliderInit();
	});
}

function packeryInit() {
	// Packery.prototype.shuffle = function () {
	// 	var m = this.items.length,
	// 		t, i;
	// 	while (m) {
	// 		i = Math.floor(Math.random() * m--);
	// 		t = this.items[m];
	// 		this.items[m] = this.items[i];
	// 		this.items[i] = t;
	// 	}
	// 	this.layout();
	// }
	if ($('.news-packery-list').length > 0) {
		setTimeout(() => {
			let packeryList = new Packery('.news-packery-list', {
				itemSelector: '.news-packery-item',
				gutter: 40
			});
		}, 250)
	}
	// window.onload = function () {
	// 	packeryList.shuffle();
	// };

}

function audioInit() {
	$('.audio-voice-current').on('click', function () {
		$('.audio-voice-list').fadeToggle('fast');
	})

	$('.audio-wrap').each(function () {
		var audioWrap = $(this);
		var audio = new Audio(audioWrap.data('audio-src'));
		var playButton = audioWrap.find('.audio-toggle-play');
		var timer = audioWrap.find('.audio-timer');
		var durationDisplay = audioWrap.find('.audio-duration');
		var progressBar = audioWrap.find('.audio-current');
		var timeline = audioWrap.find('.audio-timeline');
		var chooseVoice = audioWrap.find('.audio-voice-list');
		var voiceList = audioWrap.find('.audio-voice-list li');
		var voiceCurrent = audioWrap.find('.audio-voice-current');
		var voiceCurrentText = audioWrap.find('.audio-voice-current span');
		var isDragging = false;

		// Format time
		function formatTime(seconds) {
			var minutes = Math.floor(seconds / 60);
			var remainingSeconds = Math.floor(seconds % 60);
			return (minutes < 10 ? '0' + minutes : minutes) + ':' + (remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds);
		}

		// Update time and progress bar
		function updateTimeAndProgress() {
			timer.text(formatTime(audio.currentTime));
			progressBar.css('width', (audio.currentTime / audio.duration) * 100 + '%');
		}

		// Play audio
		function playAudio() {
			audio.play();
			playButton.addClass('playing');
		}

		// Pause audio
		function pauseAudio() {
			audio.pause();
			playButton.removeClass('playing');
		}

		// Set audio duration
		audio.onloadedmetadata = function () {
			durationDisplay.text(formatTime(audio.duration));
		};

		// Update time and progress while playing
		audio.ontimeupdate = function () {
			updateTimeAndProgress();
		};

		// Reset player when audio ends
		audio.onended = function () {
			pauseAudio();
			audio.currentTime = 0;
			updateTimeAndProgress();
		};

		// Play/pause button click
		playButton.on('click', function () {
			if (audio.paused) {
				playAudio();
			} else {
				pauseAudio();
			}
		});

		// Timeline click
		timeline.on('click', function (e) {
			var clickPosition = e.pageX - timeline.offset().left;
			var newTime = (clickPosition / timeline.width()) * audio.duration;
			audio.currentTime = newTime;
			updateTimeAndProgress();
		});

		// Timeline touch events
		timeline.on('touchstart touchmove', function (e) {
			e.preventDefault(); // Prevent default touch scrolling behavior

			var touch = e.originalEvent.touches[0]; // Get touch position
			var clickPosition = touch.pageX - timeline.offset().left;
			var newTime = (clickPosition / timeline.width()) * audio.duration;

			// Ensure newTime is within bounds
			if (newTime < 0) {
				newTime = 0;
			} else if (newTime > audio.duration) {
				newTime = audio.duration;
			}

			audio.currentTime = newTime;
			updateTimeAndProgress();
		});

		// Timeline drag events
		timeline.on('mousedown', function (e) {
			isDragging = true;
			updateAudioTime(e);
		});

		$(document).on('mousemove', function (e) {
			if (isDragging) {
				updateAudioTime(e);
			}
		});

		$(document).on('mouseup', function () {
			isDragging = false;
		});

		// Function to update audio time and progress bar
		function updateAudioTime(e) {
			var clickPosition = e.pageX - timeline.offset().left;
			var newTime = (clickPosition / timeline.width()) * audio.duration;
			audio.currentTime = newTime;
			updateTimeAndProgress();
		}

		// Change voice
		voiceList.on('click', function () {
			var newSrc = $(this).data('audio-src');
			var newTitle = $(this).data('title');

			audio.src = newSrc;
			voiceCurrentText.text(newTitle);
			playAudio(); // Auto-play when voice changes
			chooseVoice.fadeOut('fast');
		});

		$(document).on('click', function (e) {
			if (!$(e.target).closest(chooseVoice).length && !$(e.target).closest(voiceCurrent).length) {
				chooseVoice.fadeOut('fast');
			}
		});
	});
}

function podcastInit() {
	// Cache references to DOM elements.
	var elms = [
		"track",
		"timer",
		"duration",
		"playBtn",
		"pauseBtn",
		"back10sBtn",
		"prevBtn",
		"next10sBtn",
		"nextBtn",
		"playlistBtn",
		"volumeBtn",
		"waveformEmpty",
		"progress",
		"wave",
		"loading",
		"playlist",
		"list",
		"volume",
		"barEmpty",
		"barFull",
		"sliderBtn",
		"podcastPlaybackRate",
		"podcastPlaybackRateNumber",
		"soundMuted",
		"timeline",
		"soundMax",
		"podcastSoundMaxBtn",
	];

	elms.forEach(function (elm) {
		window[elm] = $(`.${elm}`);
	});

	var Player = function (playlist) {
		this.playlist = playlist;
		this.index = 0;

		// Display the title of the first track.
		track.innerHTML = "1. " + playlist[0].title;

		// Setup the playlist display.
		playlist.forEach(function (song) {
			var div = document.createElement("div");
			div.className = "list-song";
			div.innerHTML = song.title;
			div.onclick = function () {
				player.skipTo(playlist.indexOf(song));
			};
		});
	};
	Player.prototype = {
		/**
		 * Play a song in the playlist.
		 * @param  {Number} index Index of the song in the playlist (leave empty to play the first or current).
		 */
		play: function (index) {
			var self = this;
			var sound;

			index = typeof index === "number" ? index : self.index;
			var data = self.playlist[index];

			// If we already loaded this track, use the current one.
			// Otherwise, setup and load a new Howl.
			if (data.howl) {
				sound = data.howl;
			} else {
				sound = data.howl = new Howl({
					src: [data.file],
					html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
					onplay: function () {
						// Display the duration.
						duration.html(
							self.formatTime(Math.round(sound.duration()))
						);

						// Start updating the progress of the track.
						requestAnimationFrame(self.step.bind(self));

						// Start the wave animation if we have already loaded
						// wave.container.show();
						pauseBtn.show();
					},
					onload: function () {
						// Start the wave animation.
						// wave.container.show();
						// loading.hide();
					},
					onend: function () {
						// Stop the wave animation.
						// wave.container.hide();
						self.skip("next");
					},
					onpause: function () {
						// Stop the wave animation.
						// wave.container.hide();
					},
					onstop: function () {
						// Stop the wave animation.
						// wave.container.hide();
					},
					onseek: function () {
						// Start updating the progress of the track.
						requestAnimationFrame(self.step.bind(self));
					},
				});
			}

			// Begin playing the sound.
			sound.play();

			// Update the track display.
			track.innerHTML = index + 1 + ". " + data.title;

			// Show the pause button.
			if (sound.state() === "loaded") {
				playBtn.hide();
				pauseBtn.show();
			} else {
				// loading.show();
				playBtn.hide();
				pauseBtn.show();
			}

			// Keep track of the index we are currently playing.
			self.index = index;
		},

		/**
		 * Pause the currently playing track.
		 */
		pause: function () {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			// Puase the sound.
			sound.pause();

			// Show the play button.
			playBtn.show();
			pauseBtn.hide();
		},

		/**
		 * Skip to the next or previous track.
		 * @param  {String} direction 'next' or 'prev'.
		 */
		skip: function (direction) {
			var self = this;

			// Get the next track based on the direction of the track.
			var index = 0;
			if (direction === "prev") {
				index = self.index - 1;
				if (index < 0) {
					index = self.playlist.length - 1;
				}
			} else {
				index = self.index + 1;
				if (index >= self.playlist.length) {
					index = 0;
				}
			}

			self.skipTo(index);
		},

		/**
		 * Skip to a specific track based on its playlist index.
		 * @param  {Number} index Index in the playlist.
		 */
		skipTo: function (index) {
			var self = this;

			// Stop the current track.
			if (self.playlist[self.index].howl) {
				self.playlist[self.index].howl.stop();
			}

			// Reset progress.
			progress.width("0%");

			// Play the new track.
			self.play(index);
		},

		/**
		 * Set the volume and update the volume slider display.
		 * @param  {Number} val Volume between 0 and 1.
		 */
		volume: function (val) {
			var self = this;

			// Update the global volume (affecting all Howls).
			Howler.volume(val);

			// Update the display on the slider.

			// sliderBtn.css('left',  (window.innerWidth * barWidth + window.innerWidth * 0.05 - 25) + 'px');
		},

		/**
		 * Seek to a new position in the currently playing track.
		 * @param  {Number} per Percentage through the song to skip.
		 */
		seek: function (per) {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			// Convert the percent into a seek position.
			if (sound.playing()) {
				sound.seek(sound.duration() * per);
			}
		},

		timelineSeek: function (per) {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			sound.seek(per);
		},

		/**
		 * Seek to a new position in the currently playing track.
		 * @param  {Number} per Percentage through the song to skip.
		 */
		seekBack10Second: function (per) {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			// Convert the percent into a seek position.
			if (sound.playing()) {
				// console.log(sound.duration()*per+10);
				sound.seek(sound.seek() - per);
			}
		},

		/**
		 * Seek to a new position in the currently playing track.
		 * @param  {Number} per Percentage through the song to skip.
		 */
		seekNext10Second: function (per) {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			// Convert the percent into a seek position.
			if (sound.playing()) {
				// console.log(sound.duration()*per+10);
				sound.seek(sound.seek() + per);
			}
		},

		/**
		 * Seek to a new position in the currently playing track.
		 * @param  {Number} per Percentage through the song to skip.
		 */
		rate: function (per) {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			// Convert the percent into a seek position.
			if (sound.playing()) {
				// console.log(sound.duration()*per+10);
				// sound.seek(sound.seek() + per);
				sound.rate(per);
			}
		},

		/**
		 * The step called within requestAnimationFrame to update the playback position.
		 */
		step: function () {
			var self = this;

			// Get the Howl we want to manipulate.
			var sound = self.playlist[self.index].howl;

			// Determine our current seek position.
			var seek = sound.seek() || 0;
			timer.html(self.formatTime(Math.round(seek)));
			progress.width(((seek / sound.duration()) * 100 || 0) + "%");

			// If the sound is still playing, continue stepping.
			if (sound.playing()) {
				requestAnimationFrame(self.step.bind(self));
			}
		},

		/**
		 * Toggle the playlist display on/off.
		 */
		togglePlaylist: function () {
			var self = this;
			var display = playlist.style.display === "block" ? "none" : "block";

			setTimeout(
				function () {
					playlist.style.display = display;
				},
				display === "block" ? 0 : 500
			);
			playlist.className = display === "block" ? "fadein" : "fadeout";
		},

		/**
		 * Toggle the volume display on/off.
		 */
		toggleVolume: function () {
			var self = this;
			var display = volume.style.display === "block" ? "none" : "block";

			setTimeout(
				function () {
					volume.style.display = display;
				},
				display === "block" ? 0 : 500
			);
			volume.className = display === "block" ? "fadein" : "fadeout";
		},

		/**
		 * Format the time from seconds to M:SS.
		 * @param  {Number} secs Seconds to format.
		 * @return {String}      Formatted time.
		 */
		formatTime: function (secs) {
			var minutes = Math.floor(secs / 60) || 0;
			var seconds = secs - minutes * 60 || 0;

			return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
		},
	};

	var soundUrl = $(".podcast-player-wrap").data("audio-source");
	var rates = [0.5, 1.0, 1.5, 2.0]; // create an array of rate values
	var currentRateIndex = 1; // set the default rate index
	let isMuted = false; // Keep track of mute state

	// Setup our new audio player class and pass it the playlist.
	var player = new Player([
		{
			file: soundUrl,
			howl: null,
		},
	]);

	// Bind our player controls.
	playBtn.on("click", function () {
		player.play();
	});
	pauseBtn.on("click", function () {
		player.pause();
	});
	prevBtn.on("click", function () {
		// player.skip('prev');
	});
	nextBtn.on("click", function () {
		// player.skip('next');
	});

	function handleDragEvent(startOffset, barWidth, dragCallback) {
		return function (e) {
			var startX =
				e.type === "touchstart"
					? e.touches[0].pageX - startOffset
					: e.pageX - startOffset;
			var percentage = startX / barWidth;
			dragCallback(Math.min(Math.max(percentage, 0), 1));
			$(document).on("mousemove", function (e) {
				var newStartX =
					e.type === "touchmove"
						? e.touches[0].pageX - startOffset
						: e.pageX - startOffset;
				var percentage = newStartX / barWidth;
				dragCallback(Math.min(Math.max(percentage, 0), 1));
			});
			$(document).on("mouseup", function (e) {
				$(document).off("mousemove");
				$(document).off("mouseup");
			});
		};
	}
	waveformEmpty.each(function (index, currentTimeline) {
		var $currentTimeline = $(currentTimeline);
		$currentTimeline.on(
			"mousedown touchstart",
			handleDragEvent(
				$currentTimeline.offset().left,
				$currentTimeline.width(),
				function (percentage) {
					player.seek(percentage);
					if (percentage === 1) player.pause();
				}
			)
		);
	});
	barEmpty.each(function (index, currentBar) {
		var $currentBar = $(currentBar);
		$currentBar.on(
			"mousedown touchstart",
			handleDragEvent(
				$currentBar.offset().left,
				$currentBar.width(),
				function (volume) {
					barFull.css("width", volume * 100 + "%");
					player.volume(volume);
				}
			)
		);
	});

	back10sBtn.on("click", function () {
		player.seekBack10Second(10);
	});
	next10sBtn.on("click", function () {
		player.seekNext10Second(10);
	});
	podcastPlaybackRate.on("click", function (i) {
		currentRateIndex = (currentRateIndex + 1) % rates.length;
		podcastPlaybackRateNumber.html(rates[currentRateIndex] + "x");
		player.rate(rates[currentRateIndex]);
	});
	// volumeBtn.on('click', function () {
	// 	player.toggleVolume();
	// });
	// volume.on('click', function () {
	// 	player.toggleVolume();
	// });

	// Setup the event listeners to enable dragging of volume slider.
	volume.on("mouseup", function () {
		window.sliderDown = false;
	});
	volume.on("touchend", function () {
		window.sliderDown = false;
	});

	var move = function (event) {
		if (window.sliderDown) {
			var x = event.clientX || event.touches[0].clientX;
			var startX = window.innerWidth * 0.05;
			var layerX = x - startX;
			var per = Math.min(
				1,
				Math.max(0, layerX / parseFloat(barEmpty.scrollWidth))
			);
			player.volume(per);
		}
	};

	volume.on("mousemove", move);
	volume.on("touchmove", move);

	soundMuted.on("click", function () {
		isMuted = !isMuted; // Toggle mute state

		// Get the Howl instance for the current track
		const sound = player.playlist[player.index].howl;

		if (sound) {
			sound.mute(isMuted); // Mute/unmute the Howl instance

			// Update button appearance (you might want to change the classes)
			if (isMuted) {
				soundMuted.addClass("muted");
				soundMuted.removeClass("unmuted");
			} else {
				soundMuted.removeClass("muted");
				soundMuted.addClass("unmuted");
			}
		}
	});
	podcastSoundMaxBtn.on("click", function () {
		// Set the global Howler volume to maximum
		Howler.volume(1.0);

		// Update the UI to reflect the maximum volume
		barFull.css("width", "100%");

		// If a sound is currently playing, update its volume as well
		const sound = player.playlist[player.index].howl;
		if (sound && sound.playing()) {
			sound.volume(1.0);
		}
	});

	// Click play timeline
	function changeCurrentUrl(url) {
		return window.history.pushState("", "", url);
	}

	function convertToSecond(timeString) {
		const [minutes, seconds] = timeString
			.split(":")
			.map((num) => parseInt(num));
		const totalSeconds = minutes * 60 + seconds;
		return totalSeconds;
	}
	var timeLineFirstClick = false;
	var urlTimeLine = new URL(window.location.href);
	timeline.on("click", function (e) {
		timeline.each(function (index, e) {
			e.classList.remove("active");
		});
		this.classList.add("active");
		const getItemTime = this.querySelector(".time").innerText;
		const totalSeconds = convertToSecond(getItemTime);

		urlTimeLine.searchParams.set("time", totalSeconds);
		changeCurrentUrl(urlTimeLine);
		console.log(player);
		if (timeLineFirstClick == false && player.playlist[0].howl === null)
			player.play();
		player.timelineSeek(totalSeconds);
		timeLineFirstClick = true;
	});
	if (urlTimeLine.searchParams.get("time") !== null) {
		const totalSeconds = urlTimeLine.searchParams.get("time");
		player.play();
		player.timelineSeek(totalSeconds);
	}

	// // Setup the "waveform" animation.
	// var wave = new SiriWave({
	// 	// container: waveform,
	// 	width: window.innerWidth,
	// 	height: window.innerHeight * 0.3,
	// 	cover: true,
	// 	speed: 0.03,
	// 	amplitude: 0.7,
	// 	frequency: 2
	// });
	// wave.start();

	// Update the height of the wave animation.
	// These are basically some hacks to get SiriWave.js to do what we want.
	var resize = function () {
		var height = window.innerHeight * 0.3;
		var width = window.innerWidth;
		// wave.height = height;
		// wave.height_2 = height / 2;
		// wave.MAX = wave.height_2 - 4;
		// wave.width = width;
		// wave.width_2 = width / 2;
		// wave.width_4 = width / 4;
		// wave.canvas.height = height;
		// wave.canvas.width = width;
		// wave.container.style.margin = -(height / 2) + 'px auto';

		// Update the position of the slider.
		var sound = player.playlist[player.index].howl;
		if (sound) {
			var vol = sound.volume();
			var barWidth = vol * 0.9;
			// sliderBtn.css('left', (window.innerWidth * barWidth + window.innerWidth * 0.05 - 25) + 'px');
		}
	};
	window.addEventListener("resize", resize);
	resize();

	// window.onload = (event) => {
	// 	setTimeout(function(){
	// 		player.play();
	// 	},1500)
	// };

	$(".podcast-player-close").on("click", function () {
		$(this).parents(".podcast-player-pin").addClass("not-pin");
	});

	// Click play timeline
	function convertToSecond(timeString) {
		const [minutes, seconds] = timeString
			.split(":")
			.map((num) => parseInt(num));
		const totalSeconds = minutes * 60 + seconds;
		return totalSeconds;
	}

	var timeLineFirstClick = false;

	// Delegate the click event to the parent element
	$(".podcast-timeline-list").on("click", ".podcast-timeline-item", function (e) {
		// Remove active class from all items
		$(".podcast-timeline-item").removeClass("active");
		// Add active class to the clicked item
		$(this).addClass("active");

		const getItemTime = $(this).find(".time").text();
		const totalSeconds = convertToSecond(getItemTime);

		// Autoplay when timeline item is clicked
		if (!timeLineFirstClick && player.playlist[0].howl === null) {
			player.play();
		}

		player.timelineSeek(totalSeconds);
		timeLineFirstClick = true;
	});

}


function loadMoreItem() {
	$('.load-more-wrap').each(function () {
		let $this = $(this);
		let loadMore = $this.data('load-more')
		let loadMoreItem = $this.find('.load-more-item');
		let loadMoreButton = $this.find('.load-more-button');
		$(loadMoreItem).slice(0, loadMore).show();

		$(loadMoreButton).on('click', function (e) {
			e.preventDefault();
			let loadMoreItemHidden = $this.find('.load-more-item:hidden');
			$(loadMoreItemHidden).slice(0, loadMore).slideDown();
			let loadMoreItemHiddenLength = $this.find('.load-more-item:hidden').length;
			if ($(loadMoreItemHiddenLength).length == 0) {
				$(loadMoreButton).fadeOut('slow');
			}
		});
	})
}
